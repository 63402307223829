<template>
  <div class="row">
    <b-container fluid>
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <MaintenanceBackup @success="onBackupSuccess()" />
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <MaintenanceRecovery :dataSend=backupId />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {
  BRow, BCol, BContainer,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import MaintenanceRecovery from '@/views/maintenance/recovery/MaintenanceRecovery.vue'
import MaintenanceBackup from '@/views/maintenance/backup/MaintenanceBackup.vue'

export const Attr = Object.freeze({
  store: 'maintenance',
})
export default {
  name: 'Maintenance',
  directives: {
    Ripple,
  },
  data() {
    return {
      backupId: null,
    }
  },
  components: {
    BContainer,
    BRow,
    BCol,
    MaintenanceBackup,
    MaintenanceRecovery,
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    async onBackupSuccess(data) {
      this.backupId = new Date()
      this.backupId = { ...this.backupId }
    },
  },
}
</script>
